<div class="app">
  <div class="app__inner" [class.anim--in]="loading$()">
    @if (dictionaryLoaded$()) {
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [menuItems]="menuItems$()"
        [funnel]="funnel$()"
      >
        @if (dictionaryLoaded$()) {
          <app-lang-switcher [languages]="languages$()" [activeLang]="activeLanguage$() ?? ''"></app-lang-switcher>
        }
      </app-topbar>
    }

    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    @if (dictionaryLoaded$()) {
      <app-footer [domain]="domainName" [menuItems]="footerItems$()"></app-footer>
    }
  </div>
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
  @if (dictionaryLoaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonAcceptAll]="'cookie-accepteer-alles-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
      [buttonSettings]="'cookie-instellingen-knop-tekst' | fromDictionary"
      [buttonClose]="'cookie-instellingen-sluiten' | fromDictionary"
      [moreInfo]="'cookie-meer-info-tekst' | fromDictionary"
      [typeLabels]="{
        functional: 'cookie-functioneel-label' | fromDictionary,
        analytical: 'cookie-analytics-label' | fromDictionary,
        marketing: 'cookie-marketing-label' | fromDictionary,
      }"
      [typeDescriptions]="{
        functional: 'cookie-functioneel-omschrijving' | fromDictionary,
        analytical: 'cookie-analytics-omschrijving' | fromDictionary,
        marketing: 'cookie-marketing-omschrijving' | fromDictionary,
      }"
    ></fstr-cookie-notice>
  }
</div>

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
