import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';

import { StoreModule, provideState } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import * as fromStore from './store';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { ContentPageComponent } from './containers/content-page/content-page.component';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { ResizeHtmlImagesPipe } from '@teamfoster/sdk/image';

@NgModule({
  declarations: [ContentPageComponent],
  imports: [
    CommonModule,
    ContentRoutingModule,
    InViewModule,
    TextUtilityModule,
    Html5VideoPlayerModule,
    IconModule,
    InViewModule,
    CookieNgrxModule,
    ResizeHtmlImagesPipe
  ],
  exports: [],
  providers: [
    provideState(fromStore.featureKey, fromStore.reducers, {
        metaReducers: fromStore.metaReducers,
      }),
      provideEffects(fromStore.effects),
  ],
})
export class ContentModule {}
