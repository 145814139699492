@if (page$(); as page) {
  <article class="content-page topbar-spacer">
    <main class="container container-size-sm section-inset-y">
      @if (page?.title) {
        <h2 class="h2 mb-4">{{ page?.title }}</h2>
      }
      @if (page.intro) {
        <p class="lead text-primary mb-4">{{ page.intro }}</p>
      }
      <div
        class="markdown-content"
        [innerHTML]="page?.text || '' | resizeHtmlImages | stripEmbed: cookiePreferences$() ?? undefined | safe"
      ></div>
    </main>
  </article>
}
