import { Component, OnInit, Inject, PLATFORM_ID, ChangeDetectionStrategy, InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentPage } from '../../models';
import { Store } from '@ngrx/store';
import { ContentState } from '../../store';
import { isPlatformBrowser } from '@angular/common';
import * as selectors from '../../store/selectors/content-pages.selector';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPageComponent implements OnInit {
  private store = inject(Store);
  page$ = this.store.selectSignal(selectors.getSelectedContentPage);
  cookiePreferences$ = this.store.selectSignal(getCookiePreferences);

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
}
