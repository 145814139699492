import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule, provideStore } from '@ngrx/store';
import { StoreRouterConnectingModule, provideRouterStore } from '@ngrx/router-store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { DictionaryNgrxModule, FromDictionaryPipe, LanguageService, ProvideDictionaryNgrx } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule, ProvideIcons } from '@teamfoster/sdk/icon';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';

import localeNl from './nl';
import { registerLocaleData } from '@angular/common';

// not used in production
import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools';
import { NotFoundComponent } from './404.component';
import { CookieConfig, CookieNgrxModule, CookieNoticeComponent, ProvideCookiesNgrx } from '@teamfoster/sdk/cookie-ngrx';
import { LanguageNgrxModule, LanguageInterceptor, ProvideLanguageNgrx } from '@teamfoster/sdk/language-ngrx';
import { ContentModule } from './content/content.module';
import { DynamicFormConfig, DynamicFormModule } from '@teamfoster/dynamic-forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ImageFlowConfig, ProvideImageProcessor } from '@teamfoster/sdk/image';
import { provideRouter } from '@angular/router';

const dynamicFormConfig: DynamicFormConfig = {
  genericInputErrorMessage: 'TODO: genericInputErrorMessage',
  genericServerErrorMessage: 'TODO: genericServerErrorMessage',
};

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-74544R797R',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Accept all',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    DynamicFormModule.forRoot(dynamicFormConfig),
    ContentModule,
    CoreModule,
    FromDictionaryPipe,
    CookieNoticeComponent,
  ],
  providers: [
    LanguageService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    provideEffects(appEffects),
    provideRouterStore({ serializer: CustomSerializer }),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    ProvideIcons({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    ProvideDictionaryNgrx({}),
    ProvideLanguageNgrx({ defaultLanguage: 'en-GB' }),
    ProvideCookiesNgrx(cookieConfig),
    ProvideImageProcessor(ImageFlowConfig, {}),
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
