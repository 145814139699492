import { Injectable } from '@angular/core';


import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';
import { SetTitle, SetTags } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { StringUtility } from '@teamfoster/sdk/text-utility';

@Injectable({ providedIn: 'root' })
export class ContentPageMetaGuard  {
  private dict = new FromDictionaryPipe(this.lang);

  constructor(private store: Store<fromStore.ContentState>, private lang: LanguageService) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getSelectedContentPage).pipe(
      tap(page => {
        this.store.dispatch(SetTitle({ title: `${page.title} ${this.dict.transform('meta-titel-suffix')}` }));
        this.store.dispatch(
          SetTags({
            properties: {
              'description': StringUtility.Truncate(StringUtility.StripHTML(page.text), 157, '...'),
              'og:image': page.imageUrl,
              'og:title': `${page.title} ${this.dict.transform('meta-titel-suffix')}`,
              'og:description': StringUtility.Truncate(StringUtility.StripHTML(page.text), 157, '...'),
            },
          })
        );
      }),
      map(a => true),
      take(1)
    );
  }
}
